import React from "react"
import Layout from "../components/layout"
import FavContents from "../components/Wishlist/FavContents"
import Instagram from "../components/Instagram"
import SEO from "../components/seo"

const FavPage = () => {
  return (
    <Layout classExtra="nofooter">
      <SEO
        title="Terms/Conditions"
        keywords={[
          `australian`,
          `exclusive`,
          `jewellery`,
          `engagement`,
          `wedding`,
        ]}
      />
      <div className="cartwrap">
        <FavContents />
      </div>
      <div className="inner">
        <div className="instatext">Latest from Instagram</div>
        <div className="innerbackground">
          <Instagram />
        </div>
      </div>
    </Layout>
  )
}

export default FavPage
