import React, { useContext } from "react"
import PropTypes from "prop-types"
import { FavContext } from "../FavProvider"
import { ProductsContext } from "../ProductsProvider"

const AddFav = ({ productId }) => {
  const { addfav, remove, hasfav } = useContext(FavContext)
  const { products } = useContext(ProductsContext)
  const product = products.filter((p) => p.id === productId)[0]
  const isFaved = hasfav(product.id)
  const styleForHeart = isFaved ? "fa fa-heart" : "fa fa-heart-o"
  return (
    <div>
      <button
        className="favcombinedwish"
        onClick={() => {
          if (isFaved) {
            remove(product.id)
          } else {
            addfav(product.id)
          }
        }}
      >
        <div className="productfav">
          <i className={styleForHeart} aria-hidden="true"></i>
        </div>
        <div className="wishlistaddnew">
          {isFaved ? "Remove from wishlist" : "Add to wishlist"}
        </div>
      </button>
    </div>
  )
}

AddFav.propTypes = {
  productId: PropTypes.string.isRequired,
}

export default AddFav
