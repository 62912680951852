import React, { useContext } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import AddFavWish from "./AddFavWish"
import Hint from "../Hint"
import AddProductModal from "../Product/AddProductModal"
import { CartContext } from "../Cart/CartProvider"
import { ProductsContext } from "../ProductsProvider"
import slug from "slug"

import {
    FacebookShareButton,
    TwitterShareButton,
    PinterestShareButton,
    WhatsappShareButton,
    RedditShareButton,
    EmailShareButton,
    FacebookIcon,
    TwitterIcon,
    PinterestIcon,
    WhatsappIcon,
    RedditIcon,
    EmailIcon,
} from "react-share"

const FavItem = ({ productId }) => {
  const { products } = useContext(ProductsContext)
  /* Menu */
  const { toggleCart } = useContext(CartContext)

  const product = products.filter((p)=> p.id === productId)[0] || {}
  /* Product Images */
  const toggleMenu = toggleCart
  const shareUrl = product.url
  const title = product.name + " || Dianne Rowe Private Collection"

  return ( 
      <div className="favitemwrap">
      <div className="favimagesection">
        <Link to={`/collection/${slug(product.name)}`}>
          <img src={product.images[0].src} alt={product.name}></img>
        </Link>
      </div>
      <div className="wishlistcontentwrap col">
        <div>
          <div style={{ fontWeight: "bold", float: "left", marginTop: "2vh" }}>
            {product.name}
          </div>
        </div>
        {/* <div className="drophint">
          <Hint>
            {" "}
            <div className="Demo__some-network">
              <FacebookShareButton
                url={shareUrl}
                quote={title}
                className="Demo__some-network__share-button"
              >
                <FacebookIcon size={30} round />
              </FacebookShareButton>
            </div>
            <div className="Demo__some-network">
              <TwitterShareButton
                url={shareUrl}
                title={title}
                className="Demo__some-network__share-button"
              >
                <TwitterIcon size={30} round />
              </TwitterShareButton>
            </div>
            <div className="Demo__some-network">
              <WhatsappShareButton
                url={shareUrl}
                title={title}
                separator=":: "
                className="Demo__some-network__share-button"
              >
                <WhatsappIcon size={30} round />
              </WhatsappShareButton>
            </div>
            <div className="Demo__some-network">
              <PinterestShareButton
                url={String(window.location)}
                media={product.images[0]}
                windowWidth={1000}
                windowHeight={730}
                className="Demo__some-network__share-button"
              >
                <PinterestIcon size={30} round />
              </PinterestShareButton>
            </div>
            <div className="Demo__some-network">
              <RedditShareButton
                url={shareUrl}
                title={title}
                windowWidth={660}
                windowHeight={460}
                className="Demo__some-network__share-button"
              >
                <RedditIcon size={30} round />
              </RedditShareButton>
            </div>
            <div className="Demo__some-network">
              <EmailShareButton
                url={shareUrl}
                subject={title}
                body="body"
                className="Demo__some-network__share-button"
              >
                <EmailIcon size={30} round />
              </EmailShareButton>
            </div>
          </Hint>
        </div> */}
        <div className="favwishwrap">
          <AddFavWish productId={productId} />
        </div>
        <div className="favwishwrap">
          <div className="favspacer">
            <AddProductModal
              productImage={product.images[0].src}
              product={product}
              showCart={() => {
                toggleMenu(true)
              }}
            >
              <button className="favcombinedwish">
                <div className="wishlistaddnew">Add to cart</div>
              </button>
            </AddProductModal>
          </div>
        </div>
      </div>
    </div>
  )
}

FavItem.propTypes = {
  product: PropTypes.any.isRequired,
}

export default FavItem
