import React, { useContext } from "react"
import { FavContext } from "../FavProvider"
import FavItem from "./FavItem"

const FavContent = () => {
  const { contents, favcount } = useContext(FavContext)


  return (
    <div className="favcontentswrap">
      <div className="yourcartheader">Wishlist</div>
      {favcount === 0 && (
        <span>
          <div
            className="cartnoitems"
            style={{
              marginBottom: "22vh",
            }}
          >
            No items in wishlist.
          </div>
        </span>
      )}
      {contents.map(([productId]) => (
        <FavItem key={productId} productId={productId} />
      ))}
    </div>
  )
}

export default FavContent
